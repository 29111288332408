<div class="header-container">
  <div class="main-padding home-padding d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center">
      <img class="logo ml-2" src="assets/svg/logo.svg" alt="logo" (click)="goHome()" width="100%" height="100%"/>
      <div class="d-sm-none d-flex menu-buttons">
        <button [attr.aria-label]="item.label | translate" class="navBar-button" mat-ripple
                *ngFor="let item of sideMenuButtons" (click)="goToPage(item, false)">
          {{item.label | translate}}
        </button>
      </div>
    </div>
    <div class="d-flex gap-12 d-sm-none">
      <div class="download-now"> {{'header.downloadNow' | translate}} </div>
      <div *ngFor="let item of buttons" class="download-item"
           (click)="openLink(item)">
        <img [src]="item.icon" [alt]="item.title" width="196" height="55"/>
      </div>
    </div>
    <div @scaleFadeIn *ngIf="imageService.isMobile">
      <button class="menu-button" mat-icon-button aria-label="Open Menu" (click)="toggleMenu()">
        <div class="nav-icon" [ngClass]="isMenuOpen ? 'open' : ''">
          <span class="menu-stroke"></span>
          <span class="menu-stroke"></span>
          <span class="menu-stroke"></span>
          <span class="menu-stroke"></span>
        </div>
      </button>
    </div>
  </div>
  <div #mobileMenu id="mobileMenu" class="col-lg-none mobile-menu">
    <div #measuringWrapper id="measuringWrapper">
      <button [attr.aria-label]="item.label | translate" class="mobile-button" mat-ripple
              *ngFor="let item of sideMenuButtons" (click)="goToPage(item, true)">
        {{item.label | translate}}
      </button>
    </div>
  </div>
</div>
