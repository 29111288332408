import {Component, ElementRef, HostListener, Injector, ViewChild} from '@angular/core';
import {sideMenu} from "../../../../@canguru/static/sideMenu";
import {NavigationItem} from "../../../../@canguru/interfaces/navigation-item.interface";
import {BaseComponent} from "../../../../@canguru/components/base/base.component";
import {RegisterEarlyAccessModalComponent} from "../register-early-access-modal/register-early-access-modal.component";
import {scaleFadeIn400ms} from "../../../../@canguru/animations/scale-fade-in.animation";
import {storeButtons} from "../../../shared/statics/store";
import {StoreDownloadButton} from "../../../shared/interfaces/storeButtons";
import {Gtag} from "angular-gtag";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [scaleFadeIn400ms]
})
export class HeaderComponent extends BaseComponent {
  isMenuOpen = false;
  sideMenuButtons = sideMenu;
  buttons = storeButtons;
  @ViewChild('measuringWrapper', {static: true}) measuringWrapper: ElementRef | undefined;
  @ViewChild('mobileMenu', {static: true}) mobileMenu: ElementRef | undefined;

  constructor(injector: Injector, private gtag: Gtag) {
    super(injector);
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
    if (this.measuringWrapper && this.mobileMenu) {
      const height = this.measuringWrapper.nativeElement.clientHeight;
      this.mobileMenu.nativeElement.style.height = this.isMenuOpen ? `${height}px` : 0;
    }
  }

  goToPage(navigation: NavigationItem, fromMobile = false): void {
    if (fromMobile) this.toggleMenu();
    if (navigation.type === 'modal') {
      this.dialog.open(RegisterEarlyAccessModalComponent);
      return;
    }
    this.router.navigateByUrl(navigation.route).then();
  }

  goHome(): void {
    this.router.navigateByUrl('').then();
  }

  openLink(item: StoreDownloadButton) {
    this.gtag.event('download_click', {
      event_category: 'Download',
      event_label: item.event
    });
    window.open(item.link, '_blank');
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: Event) {
    this.isMenuOpen = false;
    if (this.mobileMenu) {
      this.mobileMenu.nativeElement.style.height = 0;
    }
  }
}
