import {Component} from '@angular/core';
interface SocialFooterItem {
  icon: string;
  url: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
  socialMedia: SocialFooterItem[] = [{
    icon: 'facebook',
    url: 'https://www.facebook.com/profeshdating/'
  }, {
    icon: 'instagram',
    url: 'https://www.instagram.com/profeshdating/'
  }, {
    icon: 'twitter',
    url: 'https://www.twitter.com/profeshdating/'
  }, {
    icon: 'linkedin',
    url: 'https://www.linkedin.com/company/profeshdating/'
  }];

  goToLink(url: string): void {
    window.open(url, '_blank');
  }
}
