import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {MatButtonModule} from "@angular/material/button";
import { CardItemComponent } from './components/card-item/card-item.component';
import { ImageBannerComponent } from './components/image-banner/image-banner.component';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {ListSkeletonComponent} from "./components/list-skeleton/list-skeleton.component";
import { EarlyAccessFormComponent } from './components/early-access-form/early-access-form.component';
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatDialogModule} from "@angular/material/dialog";
import { LoadingComponent } from './components/loading/loading.component';
import {LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks} from "ng-lazyload-image";



@NgModule({
  declarations: [
    CardItemComponent,
    ImageBannerComponent,
    ListSkeletonComponent,
    EarlyAccessFormComponent,
    LoadingComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    NgxSkeletonLoaderModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDialogModule,
    LazyLoadImageModule
  ],
  exports: [
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    CardItemComponent,
    ImageBannerComponent,
    ListSkeletonComponent,
    EarlyAccessFormComponent,
    LoadingComponent,
    LazyLoadImageModule
  ],
  providers: [
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
  ]
})
export class SharedModule { }
