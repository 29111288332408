import {Component, Injector} from '@angular/core';
import {FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {ContactForm} from "../../../shared/interfaces/api/contact";
import {BaseComponent} from "../../../../@canguru/components/base/base.component";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends BaseComponent {
  form: FormGroup;

  constructor(injector: Injector) {
    super(injector);
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      subject: ['', Validators.required],
      details: ['', Validators.required]
    })
  }

  /**
   * Submit and send contact form
   * @param val
   */
  submit(val: ContactForm, formGroup: FormGroupDirective): void {
    console.log(val);
    this.loadingService.startLoading();
    this.contactService.sendContact(val).subscribe({
      next: (res) => {
        if (res.result === 'success') {
          this.form.reset();
          formGroup.resetForm();
          this.utilService.displayToast('The contact form has been successfully submitted.')
        }
      },
      error: (err: Error) => {
        if (!environment.production) console.log(err);
      }
    }).add(() => {
      this.loadingService.finishLoading();
    })
  }
}
