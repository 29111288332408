import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {LoadingService} from "../shared/services/loading.service";
import {fadeInUp400ms} from "../../@canguru/animations/fade-in-up.animation";
import {BaseComponent} from "../../@canguru/components/base/base.component";
import {Subscription} from "rxjs";
import {NavigationEnd} from "@angular/router";
import {ImageService} from "../shared/services/image.service";
import {ContactService} from "../shared/services/contact.service";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [fadeInUp400ms]
})
export class LayoutComponent implements OnInit {
  @ViewChild('contact', {static: true}) contact: ElementRef|undefined;
  constructor(public loadingService: LoadingService, public imageService: ImageService, public contactService: ContactService) {
    this.getScreenSize();
  }
  ngOnInit(): void {
    this.contactService.contactElement = this.contact;
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: Event) {
    this.imageService.isMobile = window.innerWidth < 771;
  }
}
