import {StoreDownloadButton} from "../interfaces/storeButtons";

export const storeButtons: StoreDownloadButton[] = [{
  title: 'download.downloadOn',
  description: 'download.appStore',
  icon: 'assets/svg/apple.svg',
  link: 'itms-apps://itunes.apple.com/au/app/apple-store/id6444664076?mt=8',
  event: 'Download Apple'
}, {
  title: 'download.getItOn',
  description: 'download.playStore',
  icon: 'assets/svg/google-play.svg',
  link: 'https://play.google.com/store/apps/details?id=au.com.canguru.profesh',
  event: 'Download Android'
}];
