import {ElementRef, Injectable} from '@angular/core';
import {HttpService} from "./http.service";
import {ContactForm, ContactResponse} from "../interfaces/api/contact";
import {Observable} from "rxjs";
import {api} from "../config/api";

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  contactElement: ElementRef|undefined;

  constructor(private http: HttpService) { }

  /**
   * Send contact request.
   * @param form
   */
  sendContact(form: ContactForm): Observable<ContactResponse> {
    return this.http.post(api.contactUs, form);
  }
}
