import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import {RouterLink, RouterOutlet} from "@angular/router";
import {SharedModule} from "../shared/shared.module";
import { RegisterForEarlyAccessComponent } from './components/register-for-early-access/register-for-early-access.component';
import {MatRippleModule} from "@angular/material/core";
import { RegisterEarlyAccessModalComponent } from './components/register-early-access-modal/register-early-access-modal.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import { ContactComponent } from './components/contact/contact.component';


@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    RegisterForEarlyAccessComponent,
    RegisterEarlyAccessModalComponent,
    ContactComponent
  ],
    imports: [
        CommonModule,
        RouterOutlet,
        SharedModule,
        MatRippleModule,
        MatDialogModule,
        MatInputModule,
        ReactiveFormsModule,
        RouterLink
    ]
})
export class LayoutModule { }
