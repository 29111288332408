import { Injectable } from '@angular/core';
import {HttpService} from "./http.service";
import {EarlyAccessForm, EarlyAccessResponse} from "../interfaces/api/earlyAccess";
import {Observable} from "rxjs";
import {api} from "../config/api";

@Injectable({
  providedIn: 'root'
})
export class EarlyAccessService {

  constructor(private http: HttpService) { }

  register(form: EarlyAccessForm): Observable<EarlyAccessResponse> {
    return this.http.post(api.registerEarlyAccess, form);
  }
}
