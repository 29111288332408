import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {ChangeDetectorRef, Injector, NgZone, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from "@angular/material/dialog";
import {UtilService} from "../../../app/shared/services/util.service";
import {BlogService} from "../../../app/shared/services/blog.service";
import {EarlyAccessService} from "../../../app/shared/services/early-access.service";
import {LoadingService} from "../../../app/shared/services/loading.service";
import {ImageService} from "../../../app/shared/services/image.service";
import {ContactService} from "../../../app/shared/services/contact.service";
import {ImagePreloadService} from "../../../app/shared/services/image-preload.service";

export class BaseComponent {
    public router: Router;
    public fb: FormBuilder;
    public cdk: ChangeDetectorRef;
    public ngZone: NgZone;
    public translateService: TranslateService;
    public route: ActivatedRoute;
    public dialog: MatDialog;
    public utilService: UtilService;
    public renderer: Renderer2;
    public blogService: BlogService;
    public earlyAccessService: EarlyAccessService;
    public loadingService: LoadingService;
    public imageService: ImageService;
    public contactService: ContactService;
  public imagePreloadService: ImagePreloadService;


    protected constructor(protected injector: Injector) {
        this.router = this.injector.get(Router);
        this.fb = this.injector.get(FormBuilder);
        this.cdk = this.injector.get(ChangeDetectorRef);
        this.ngZone = this.injector.get(NgZone);
        this.translateService = this.injector.get(TranslateService);
        this.route = this.injector.get(ActivatedRoute);
        this.dialog = this.injector.get(MatDialog);
        this.utilService = this.injector.get(UtilService);
        this.renderer = this.injector.get(Renderer2);
        this.blogService = this.injector.get(BlogService);
        this.earlyAccessService = this.injector.get(EarlyAccessService);
        this.loadingService = this.injector.get(LoadingService);
        this.imageService = this.injector.get(ImageService);
        this.contactService = this.injector.get(ContactService);
        this.imagePreloadService = this.injector.get(ImagePreloadService);
    }
}
