import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  isMobile = false;
  constructor() { }

  /**
   * Get image source depends on screen size.
   * @param path
   */
  getImageSource(path: string): string {
    if (path.includes('http')) return path;
    return `assets/images/${this.isMobile ? 'mobile' : 'desktop'}/${path}`;
  }

  /**
   * Get image source depends on screen size.
   * @param path
   */
  getDefaultImage(path: string): string {
    if (path.includes('http')) return path;
    return `assets/images/loading/${path}`;
  }
}
