import {Component, OnDestroy, OnInit} from '@angular/core';
import {StorageService} from "./shared/services/storage.service";
import {TranslateService} from "@ngx-translate/core";
import {keys} from "./shared/config/keys";
import {filter} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Profesh';

  constructor(private storageService: StorageService, private translateService: TranslateService, private router: Router) {
    this.initLanguage();
  }

  ngOnInit(): void {
    this.hideLoader();
  }

  /**
   * init language.
   */
  initLanguage(): void {
    let lang = this.storageService.getItem(keys.lang);
    if (!lang) {
      lang = 'en';
      this.storageService.setItem(keys.lang, 'en');
    }
    this.translateService.use(lang);
  }

  /**
   * Hide loader.
   */
  hideLoader() {
    const spinnerEl = document.getElementById('loading-spinner');
    if (!spinnerEl) return;
    spinnerEl.classList.add('hidden');
  }
}
