<div class="contact-container row main-padding">
  <div class="text-column col-lg-6 col-md-12 col-sm-12 d-flex flex-column justify-content-center">
    <h2 class="main-title m-0">{{'contact.title' | translate}}</h2>
    <p class="description-text">{{'contact.titleDescription' | translate}}</p>
    <p class="email">{{'contact.contactEmail' | translate}}</p>
  </div>
  <form ngForm="form" #ngForm="ngForm" class="col-lg-6 col-md-12 col-sm-12" [formGroup]="form" (ngSubmit)="form.valid && submit(form.value, ngForm)">
    <mat-form-field class="col-md-6 col-sm-12 mt-2" appearance="outline">
      <mat-label>{{'contact.firstName' | translate}}</mat-label>
      <input type="text" matInput formControlName="first_name">
      <mat-error *ngIf="form.controls['first_name'].hasError('required') && form.controls['first_name'].dirty">
        {{'contact.firstName' | translate}} is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col-md-6 col-sm-12 mt-2" appearance="outline">
      <mat-label>{{'contact.lastName' | translate}}</mat-label>
      <input type="text" matInput formControlName="last_name">
      <mat-error *ngIf="form.controls['last_name'].hasError('required') && form.controls['last_name'].dirty">
        {{'contact.lastName' | translate}} is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col-md-6 col-sm-12 mt-2" appearance="outline">
      <mat-label>{{'contact.email' | translate}}</mat-label>
      <input type="email" matInput formControlName="email">
      <mat-error *ngIf="form.controls['email'].hasError('required') && form.controls['email'].dirty">
        {{'contact.email' | translate}} is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col-md-6 col-sm-12 mt-2" appearance="outline">
      <mat-label>{{'contact.subject' | translate}}</mat-label>
      <input type="text" matInput formControlName="subject">
      <mat-error *ngIf="form.controls['subject'].hasError('required') && form.controls['subject'].dirty">
        {{'contact.subject' | translate}} is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col-md-12 col-sm-12 mt-2" appearance="outline">
      <mat-label>{{'contact.description' | translate}}</mat-label>
      <textarea rows="8" type="text" matInput formControlName="details"></textarea>
      <mat-error *ngIf="form.controls['details'].hasError('required') && form.controls['details'].dirty">
        {{'contact.description' | translate}} is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <div class="mt-1 col-md-12 col-sm-12">
      <button mat-raised-button color="primary" [attr.aria-label]="'contact.submit' | translate" class="submit col-12  mb-1 mt-1 p-0" type="submit">
        {{'contact.submit' | translate}}
      </button>
    </div>
  </form>
  <img class="background" src="assets/svg/icon.svg" alt="background"/>
</div>
