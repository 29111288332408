import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StorageService} from "./storage.service";
import {HttpOptions} from "../interfaces/http";


@Injectable({
  providedIn: 'root'
})

export class HttpService {
  token: any;

  constructor(
    public http: HttpClient,
    private storageService: StorageService
  ) {
  }


  delete(url: string, options?: HttpOptions): Observable<any> {
    const httpOptions = this.getHttpOptions(options)
    return this.http.delete(this.getUrl(url, options), httpOptions);
  }

  get(url: string, options?: HttpOptions): Observable<any> {
    const httpOptions = this.getHttpOptions(options)
    return this.http.get(this.getUrl(url, options), httpOptions);

  }

  head(url: string, options?: HttpOptions): Observable<any> {
    options = options || {};
    options.headers = options.headers || {};
    return this.http.head(url, options);
  }

  jsonp<T>(url: string, callbackParam: string): Observable<T> {
    return this.http.jsonp<T>(url, callbackParam);
  }

  options(url: string, options?: HttpOptions): Observable<any> {
    options = options || {};
    options.headers = options.headers || {};
    return this.http.options(url, options);
  }

  patch(url: string, body: any | null, options?: HttpOptions): Observable<any> {
    const httpOptions = this.getHttpOptions(options)
    return this.http.patch(this.getUrl(url, options), body, httpOptions);
  }

  post(url: string, body: any | null, options?: HttpOptions): Observable<any> {
    const httpOptions = this.getHttpOptions(options)
    return this.http.post(this.getUrl(url, options), body, httpOptions);
  }

  put(url: string, body: any | null, options?: HttpOptions): Observable<any> {
    const httpOptions = this.getHttpOptions(options)
    return this.http.put(this.getUrl(url, options), body, httpOptions);
  }

  private getHttpOptions(options?: HttpOptions): any {
    if (!options) return {};
    options = options || {};
    options.headers = options.headers || {};
    const { headers, ...queryParams } = options;
    const params = new HttpParams({ fromObject: queryParams as { [param: string]: string | number | boolean } });
    return {headers: options.headers, params};
  }

  private getUrl(url: string, options?: HttpOptions): string {
    if (!options?.id) return url;
    return `${url}/${options.id}`;
  }
}
