<div class="footer main-padding">
  <p class="app-name">{{'app.name' | translate}}.</p>
  <p class="m-footer" [innerHtml]="'footer.description' | translate">
    
  </p>
  <div class="m-footer mt-2">
    <p class="m-footer">{{'footer.followUs' | translate}}</p>
    <div class="d-flex m-footer">
      <button [attr.aria-label]="item.icon" class="social-button" mat-icon-button *ngFor="let item of socialMedia"
              (click)="goToLink(item.url)">
        <img [src]="'assets/svg/'+item.icon+'.svg'" aria-label="social link" width="100%" height="100%"/>
      </button>
    </div>
  </div>
  <div class="fit-content">
    <a [routerLink]="['/terms']">{{'footer.terms' | translate}}</a>
    <a [routerLink]="['/privacy']">{{'footer.privacy' | translate}}</a>
  </div>
  <img class="ring" src="assets/svg/footer.svg" width="100%" height="100%" alt="footer ring"/>
</div>
