import {NavigationItem} from "../interfaces/navigation-item.interface";

export const sideMenu: NavigationItem[] = [
  {
    label: 'menu.about',
    route: '/about',
    type: 'route'
  },
  {
    label: 'menu.blog',
    route: '/blog',
    type: 'route'
  },
];
